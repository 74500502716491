import { useEffect } from "react";
import "./index.css";
import { Router, Routes, Route, useLocation } from "react-router-dom";

import {LanguageProvider} from "./contexts/LanguageContext";
import LandingView from "./pages/LandingView";
import CardView from "./pages/CardView";
import NotFound from "./pages/NotFoundView";
import OnboardView from "./pages/OnboardView";
import StoryView from "./pages/StoryView";
import PrivacyPolicyView from "./pages/PrivacyPolicyView";
import { initGA, trackPageView } from "./utils/Analytics";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA4 with your measurement ID
    initGA("G-WZQSMCH9LX"); // Replace with your actual measurement ID
  }, []);

  useEffect(() => {
    // Track page views when location changes
    trackPageView(location.pathname + location.search);
  }, [location]);

  return (
    <LanguageProvider>
      <Routes>
        <Route path="/" element={<LandingView />} />
        <Route path="/cards" element={<CardView />} />
        <Route path="/onboard" element={<OnboardView />} />
        <Route path="/story" element={<StoryView />} />
        <Route path="/privacy" element={<PrivacyPolicyView />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </LanguageProvider>
  );
};

export default App;

