import {useState} from "react";
import {useLanguage} from "../contexts/LanguageContext";

function LanguageDropdown() {
  const {currentLanguage, setCurrentLanguage} = useLanguage();

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  return (
    <div className="dropdown dropdown-end">
      <div tabIndex={0} role="button" className="btn m-1 btn-primary">
        Change Language {currentLanguage !== "English" && "언어 변경"}
      </div>
      <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-10 w-52 p-2 shadow">
        <li>
          <button
            onClick={() => handleLanguageChange('English')}
            className={currentLanguage === 'English' ? 'active' : ''}
          >
            English
          </button>
        </li>
        <li>
          <button
            onClick={() => handleLanguageChange('Korean')}
            className={currentLanguage === 'Korean' ? 'active' : ''}
          >
            Korean
          </button>
        </li>
      </ul>
  </div>
  );
}

export default LanguageDropdown;
