import Footer from "../components/Footer";
import Header from "../components/Header";

function PrivacyPolicy() {
  return (
    <div className="min-h-screen flex flex-col p-12 justify-content items-center bg-base-200">
      <Header/>
      <div className="container mx-auto p-10 max-w-4xl bg-white rounded-lg shadow-sm">
        <div className="prose max-w-none">
          <h1 className="text-3xl font-bold mb-8">Privacy Policy for LettuceTalk</h1>
          <p className="text-gray-600 mb-4">Last Updated: February 5, 2025</p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Introduction</h2>
          <p className="mb-4">
            Welcome to LettuceTalk ("Application"), a web application provided by Impact Moonshots, 
            a DBA of Creashell LLC ("Company," "we," "our," or "us"). This Privacy Policy explains 
            how we collect, use, disclose, and safeguard your information when you access or use 
            our Application. By using LettuceTalk, you agree to the collection and use of 
            information in accordance with this Privacy Policy.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Legal Entity</h2>
          <p className="mb-4">
            LettuceTalk is owned and operated by:<br />
            Creashell LLC<br />
            d/b/a Impact Moonshots<br />
            20830 Stevens Creek Blvd #1074, Cupertino, CA 95014
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Information We Collect</h2>
          
          <h3 className="text-xl font-semibold mt-6 mb-3">Automatically Collected Information</h3>
          <p className="mb-4">
            We use Google Analytics and similar tracking technologies to collect standard internet 
            log information and details about visitor behavior patterns. This includes:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">IP address and location data</li>
            <li className="mb-2">Browser type, version, and preferences</li>
            <li className="mb-2">Operating system and device information</li>
            <li className="mb-2">Pages visited and navigation patterns</li>
            <li className="mb-2">Time and duration of visits</li>
            <li className="mb-2">Referring website addresses</li>
            <li className="mb-2">Network information</li>
            <li className="mb-2">Usage patterns and interaction data</li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-3">Cookies and Tracking Technologies</h3>
          <p className="mb-4">
            We use cookies, web beacons, and similar tracking technologies through Google Analytics 
            and other service providers. These technologies place small text files on your device 
            to help us analyze how users interact with our Application.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">How We Use Your Information</h2>
          <h3 className="text-xl font-semibold mt-6 mb-3">Essential Operations:</h3>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">Operate and maintain the Application</li>
            <li className="mb-2">Provide technical support</li>
            <li className="mb-2">Ensure security and prevent fraud</li>
            <li className="mb-2">Monitor and analyze usage patterns</li>
            <li className="mb-2">Improve user experience</li>
          </ul>

          <h3 className="text-xl font-semibold mt-6 mb-3">Analytics and Development:</h3>
          <ul className="list-disc pl-6 mb-4">
            <li className="mb-2">Analyze website traffic and usage trends</li>
            <li className="mb-2">Develop new products and services</li>
            <li className="mb-2">Test and improve Application features</li>
            <li className="mb-2">Debug and fix technical issues</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Data Sharing and Disclosure</h2>
          <p className="mb-4">
            We do not sell, rent, or trade your personal information. We may share anonymous, 
            aggregated data with service providers and as required by law.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Data Security</h2>
          <p className="mb-4">
            We implement industry-standard technical, administrative, and physical security 
            measures to protect your information, including encryption and access controls.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Future Updates to This Policy</h2>
          <p className="mb-4">
            While we do not currently collect personal information directly from users, we may 
            do so in the future. We reserve the right to modify this Privacy Policy at any time.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Your Rights</h2>
          <p className="mb-4">
            Depending on your jurisdiction, you may have certain rights regarding your information, 
            including access, rectification, erasure, and data portability rights.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Children's Privacy</h2>
          <p className="mb-4">
            The Application is not intended for children under 13 years of age. We do not 
            knowingly collect personal information from children under 13.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Information</h2>
          <p className="mb-4">
            For questions or concerns about this Privacy Policy, please contact us at:<br />
            Impact Moonshots<br />
            c/o Creashell LLC<br />
            20830 Stevens Creek Blvd #1074, Cupertino, CA 95014<br />
            Email: care@impactmoonshotfactory.com
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Governing Law</h2>
          <p className="mb-4">
            This Privacy Policy is governed by and construed in accordance with the laws of 
            the State of California, United States, without regard to its conflict 
            of law provisions.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Severability</h2>
          <p className="mb-4">
            If any provision of this Privacy Policy is found to be unenforceable or invalid, 
            that provision will be limited or eliminated to the minimum extent necessary so 
            that this Privacy Policy will otherwise remain in full force and effect.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Entire Agreement</h2>
          <p className="mb-4">
            This Privacy Policy, together with our Terms of Service, constitutes the entire 
            agreement between you and Company regarding the use of the Application.
          </p>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;

