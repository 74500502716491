import FamilyImage from "../images/myfamily.png";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {useNavigate} from "react-router-dom";

function StoryView() {
  const navigate = useNavigate();
  const handleStartClick = () => {
    navigate("/onboard"); 
  }

  return (
    <div className="min-h-screen flex flex-col p-12 justify-content items-center bg-base-200">
      <Header/>
      <div className="shadow-xl flex flex-col bg-base-100 card p-12">
        <img src={FamilyImage} className="w-96 mx-auto"/>
        <h1 className="font-bold text-2xl mt-4">Our Story</h1>
        <p className="mt-2 whitespace-pre-line">
          {`Hi, I'm Michelle! I was born in Koreatown, LA, and I'm the daughter of South Korean immigrants. My mom has worked hard to support me and my brother as we grew up, and as I think about her getting older, I've found it hard to know what to ask and how my brother and I should prepare to take care of her as she ages.

          LettuceTalk was my answer to this challenge, to help Korean-American families like mine prepare early for our parents growing older in as delightful, grateful, and thoughtful of a way as possible. Problems like these are why I founded Impact Moonshots, an AI studio for social impact— I want to create joyful, world-class technology for communities and social challenges that usually go ignored in Silicon Valley.

          To all us immigrant children out there with aging parents, I see you, and I hope this reaches you with a lot of love.

          Warm wishes,
          Michelle
          `}
        </p>
        {/*<h1 className="font-bold text-2xl mt-4">우리 이야기</h1>
        <p className="mt-2 whitespace-pre-line">
        {`안녕하세요, 저는 미셸입니다! 저는 LA 코리아타운에서 태어났고, 한국 이민자 부모님 밑에서 자랐습니다. 엄마는 저와 동생을 키우기 위해 평생을 헌신해 오셨습니다. 이제 엄마가 나이가 드시는 걸 보면서, 어떤 질문을 드려야 할지, 동생과 함께 어떻게 준비해야 할지 고민이 많았습니다.

          우리 가족 문화에서 특정 대화 주제에 대한 낙인이 있어, 엄마에게 미리 알아두어야 할 재정, 건강, 그리고 생애 말기 돌봄에 관한 질문을 꺼내는 것이 쉽지 않았습니다. 엄마가 건강하고 행복하신데도 불구하고, 동생과 함께 이런 슬픈 주제를 이야기하는 것이 참 어색했어요. 🥲

          베추톡은 저와 같은 한국계 미국인 가족들이 부모님의 노후를 기쁘고 감사한 마음으로, 깊이 있게 미리 준비할 수 있도록 돕기 위해 제가 찾은 해답입니다. 이런 문제들이 바로 제가 Impact Moonshots, 사회적 영향을 위한 AI 스튜디오를 창립한 이유입니다— 실리콘밸리에서 종종 외면받는 커뮤니티와 사회적 문제를 위해 세계 최고 수준의 기쁘고 의미 있는 기술을 만들고 싶었습니다.

          나이가 드신 부모님을 모시고 계신 모든 이민자 자녀들께, 저도 같은 처지에 있습니다. 이 메시지가 여러분께 많은 사랑으로 전해지길 바랍니다.

          따뜻한 마음을 담아,
          미셸
          `}

        </p>
        */}
        <a href="https://www.impactmoonshotfactory.com" target="_blank" className="mt-4 w-fit btn btn-primary flex flex-col cursor-pointer">
          <label>Follow Our Journey</label>
          <label>우리의 여정을 따라오세요</label>
        </a>

      </div>
      <Footer/>
    </div>
  );
}

export default StoryView;


