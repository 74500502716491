import { useNavigate } from "react-router-dom";
import Logo from "../images/logo.png";
import LanguageDropdown from "./LanguageDropdown";
import {useState} from "react";
import {useLanguage} from "../contexts/LanguageContext";

function Header() {
  const {currentLanguage} = useLanguage();

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleStoryClick = () => {
    navigate("/story");
  };

  return (
    <header className="w-full flex items-center flex-col space-y-3 md:flex-row md:space-y-0 bg-transparent rounded-xl mb-8">
      {/* Logo and name */}
      <div className="flex flex-row space-x-2 cursor-pointer items-center" onClick={handleLogoClick}>
        <img src={Logo} alt="Logo" className="h-10" />
        <div>LettuceTalk</div>
        {currentLanguage !== "English" && <div>배추톡</div>}
      </div>

      <button className="flex flex-row md:ml-auto btn btn-outline" onClick={handleStoryClick}>
        <div>
          Our Story
        </div>
        {currentLanguage !== "English" &&
          <div className="ml-1">
            우리 이야기
          </div>
        }
      </button>
      <LanguageDropdown currentLanguage="English"/>
    </header>
  );
}

export default Header;

