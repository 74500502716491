import ReactGA from 'react-ga4';

// Initialize GA4
export const initGA = (measurementId) => {
  ReactGA.initialize(measurementId);
};


// Track page views
export const trackPageView = (page) => {
  ReactGA.send({
    hitType: 'pageview',
    page: page
  });
};

// Track custom events
export const trackEvent = (category, action, label, value = null) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value
  });
};

