import Emoji from "./Emoji";

function Footer() {
  return (
    <div className="flex flex-row space-x-2 mt-auto text-xs pt-4">
      <div>
        Made with <Emoji symbol="💜" label="purple heart"/> by <a href="https://impactmoonshotfactory.com" target="_blank" className="link link-info">Impact Moonshots</a>
      </div>
      <div>|</div>
      <a href="/privacy" className="link link-info" target="_blank">Privacy Policy</a>
    </div>
  );
}

export default Footer;

