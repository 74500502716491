import ParentImage from "../images/parent.png";
import ChildrenImage from "../images/children.png";
import {useLanguage} from "../contexts/LanguageContext";

function QuestionCard({ isPreviousDisabled, onPreviousClick, onNextClick, title, subtitle, translation }) {
  const {currentLanguage} = useLanguage();
  // Determine the image source based on the subtitle
  const imageSrc = subtitle.toLowerCase().includes("parent")
    ? ParentImage : ChildrenImage

  // Determine the translation based on the subtitle
  var recipientTranslation = "";
  if (subtitle.toLowerCase().includes("parent")) {
    recipientTranslation = "부모님께 드리는 질문";
  } else if (subtitle.toLowerCase().includes("sibling")) {
    recipientTranslation = "형제자매에게 드리는 질문";
  } else if (subtitle.toLowerCase().includes("child")) {
    recipientTranslation = "아이들에게 드리는 질문";
  }

  return (
    <div className="card bg-base-100 w-96 min-h-[680px] shadow-xl p-12 mb-4">
      <div className="mb-4">
        {/* Conditionally rendered image */}
        <img src={imageSrc} alt="Conditional" className="mb-4 w-36" />
        <p className="text mb-2">{subtitle}</p>
        <h2 className="text-2xl font-bold">{title}</h2>
      </div>
      {currentLanguage !== "English" &&
        <div className="my-4">
          <p className="text mb-2">{recipientTranslation}</p>
          <h2 className="text-2xl">{translation}</h2>
        </div>
      }
      <div className="flex space-x-4 mt-auto">
        <button
          className="btn"
          onClick={onPreviousClick}
          disabled={isPreviousDisabled}
        >
          Previous {currentLanguage !== "English" && "이전"}
        </button>
        <button
          className="btn btn-primary"
          onClick={onNextClick}
        >
          Next {currentLanguage !== "English" && "다음"}
        </button>
      </div>
    </div>
  );
};

export default QuestionCard;

